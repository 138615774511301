import React, { useEffect, useState } from 'react';
import './MainBody.css';
import LeftProfile from "./LeftContainer/leftprofile";
import RightProfile from "./RightContainer/rightprofile";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setCover, setSelectedColorData,setSelectedSubOutfitData, setSelectedOutfitData } from '../../redux/actions';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function MainBody() {
  const { tailor_id } = useParams();
  const dispatch = useDispatch();

  const baseURL = `https://backend.darzeeapp.com/portfolio/?username=${tailor_id}`;

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(baseURL)
      .then((response) => {
        if(response.data.portfolio_id){
          // console.log(response);
          if(response.data.cover_image_link){
            dispatch(setCover(response.data.cover_image_link));
          }
          else{
            dispatch(setCover([]));
          }
          dispatch(setSelectedOutfitData([]));
          dispatch(setSelectedSubOutfitData([]));
          dispatch(setSelectedColorData([]));

          document.title = response.data.tailor_name;

          setUser(response.data);
          localStorage.setItem('pid', JSON.stringify(response.data.portfolio_id));
          setIsLoading(false);
        }
        else{
          setError(error.message); 
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error.message); 
        setIsLoading(false);
      });
  }, [baseURL, dispatch, error]);

  return (
    <>
      {isLoading ? (
        <div style={{alignContent:"center",justifyContent:"center", height:"100px", display:"flex", flexWrap:"wrap"}}>
          <FontAwesomeIcon icon={faSpinner} spinPulse size="2x" />
        </div>
      ) : error ? (
        <h6 style={{alignContent:"center",justifyContent:"center", height:"100px", display:"flex", flexWrap:"wrap"}}>Boutique not found</h6>
      ) : (
        <>
          <Helmet>
            <title>{user.tailor_name}</title> 
            <meta name="description" content={user.portfolio_about} />
            <meta property="og:description" content={user.portfolio_about} />
            <meta property="og:title" content={user.tailor_name} />
         </Helmet>

          <Header />
          <div className="bodyContainer">
            <div className="leftBodyContainer">
              <LeftProfile user={user} />
            </div>
            <div className="rightBodyContainer">
              <RightProfile user={user} />
            </div>
          </div>
          <Footer user={user} />
        </>
      )}
    </>
  );
}

export default MainBody;
