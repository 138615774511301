import React, { useState, useEffect } from "react";
import ProfileCard from "./ProfileCard/ProfileCard";
import FiltersComponent from "./FiltersComponent";

import "./leftprofile.css";
import FiltersComponentMobile from "./FiltersComponentMobile";

const LeftProfile = ({ user }) => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 768);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <ProfileCard user={user} />
      {isMobileView ? (
        <FiltersComponentMobile />
      ) : (
        <FiltersComponent />
      )}
    </>
  );
};

export default LeftProfile;
