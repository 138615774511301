import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setCardData, appendCardData, setLoading } from '../../../redux/actions';
import axios from "axios";
import './leftprofile.css';

const FiltersComponentMobile = () => {
    const { tailor_id } = useParams();
    const portfolio_id = localStorage.getItem('pid');
    const dispatch = useDispatch();

    const uniqueFilters = `https://backend.darzeeapp.com/portfolio/filters?username=${tailor_id}`;

    const [isAllActive, setIsAllActive] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedOutfitTypes, setSelectedOutfitTypes] = useState([]);
    const [outfitTypes, setOutfitTypes] = useState([]);

    const [error, setError] = useState(null);

    const handleOutfitTypeChangeMobile = (outfitIndex) => {
        if (outfitIndex === undefined) {
            setSelectedOutfitTypes([]);
        } else {
            setSelectedOutfitTypes([outfitIndex]);
        }
    };

    useEffect(() => {
        axios.get(uniqueFilters)
            .then((response) => {
                const outfitFilters = response.data.outfit_filter.map((item) => ({
                    [item.name]: item.index,
                }));
                setOutfitTypes(outfitFilters);
            })
            .catch((error) => {
                setError(error);
            });
    }, [uniqueFilters]);

    const [hasMoreData, setHasMoreData] = useState(true);

    const fetchMoreData = () => {
        if (selectedOutfitTypes.length === 0) {
            if (!hasMoreData) {
                // console.log('No more data');
                return;
            }
            dispatch(setLoading(true));

            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);

            // console.log(nextPage);

            const getPortfolioSuboutift = `https://backend.darzeeapp.com/portfolio/${portfolio_id}/portfolio_outfit?page_no=${nextPage}&count=2`;

            axios.get(getPortfolioSuboutift)
                .then((response) => {
                    const outfitDetails = response.data.outfit_details;
                    // console.log(outfitDetails);
                    if (!outfitDetails || outfitDetails.length === 0) {
                        setHasMoreData(false);
                        // console.log('No more data');
                    } else {
                        dispatch(appendCardData(outfitDetails));
                    }
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    dispatch(setLoading(false));
                    // console.log('Done fetching more data');
                });
        }
    };
    

    useEffect(() => {
        if (portfolio_id) {
            // console.log("working");
            // console.log(currentPage);
            setCurrentPage(0);
            const getPortfolioSuboutift = selectedOutfitTypes.length > 0
                ? `https://backend.darzeeapp.com/portfolio/${portfolio_id}/portfolio_outfit?outfit_type=${selectedOutfitTypes.join(',')}`
                : `https://backend.darzeeapp.com/portfolio/${portfolio_id}/portfolio_outfit?page_no=${currentPage}&count=2`;

            axios.get(getPortfolioSuboutift)
                .then((response) => {
                    const outfitDetails = response.data.outfit_details;
                    // console.log(outfitDetails);
                    dispatch(setCardData(outfitDetails));
                    setHasMoreData(true);
                })
                .catch((error) => {
                    setError(error);
                });
        }
    }, [selectedOutfitTypes]);

    const handleScroll = () => {
        const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;

        if (isBottom) {
            fetchMoreData();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    if (error) {
        return <div>Something went wrong</div>;
    }


    return (
        <div className="clothfilters mobile-view">
            <hr />
            <div className="horizontal-scroll d-flex">
                <div
                    className={`filter-item ${isAllActive ? 'active' : ''}`}
                    onClick={() => {
                        handleOutfitTypeChangeMobile();
                        setIsAllActive(true);
                    }}>All</div>
                {outfitTypes.map((outfitTypeMapping, index) => {
                    const [outfitType, outfitTypeIndex] = Object.entries(outfitTypeMapping)[0];
                    const isActive = selectedOutfitTypes.includes(outfitTypeIndex);
                    return (
                        <div
                            key={index}
                            className={`filter-item ${isActive ? 'active' : ''}`}
                            onClick={() => {
                                setIsAllActive(false);
                                handleOutfitTypeChangeMobile(outfitTypeIndex);
                            }}
                        >
                            {outfitType}
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default FiltersComponentMobile