import React from 'react';
import './rootdir.css';
import darzeeicon from "../../Assets/darzee.png";

export const Rootdir = () => {
  return (
    <div className='rootdir'>
      <a href="https://www.darzeeapp.com/">
        <img src={darzeeicon} alt="darzeeicon" />
      </a>
    </div>
  )
}
