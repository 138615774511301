import { configureStore, combineReducers } from '@reduxjs/toolkit';

const coverReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_COVER_DATA':
      return action.payload;
    default:
      return state;
  }
};

const cardDataReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CARD_DATA':
      return action.payload;
    case 'APPEND_CARD_DATA':
      return [...state, ...action.payload];
    default:
      return state;
  }
};

const OutfitTypeReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_OUTFIT_TYPE':
      return action.payload;
    default:
      return state;
  }
};

const SubOutfitTypeReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_SUB_OUTFIT_TYPE':
      return action.payload;
    default:
      return state;
  }
};

const colorReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_COLOR_TYPE':
      return action.payload;
    default:
      return state;
  }
};

const subIndexReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_SUB_INDEX':
      return action.payload;
    default:
      return state;
  }
};

const loadingReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return action.payload;
    default:
      return state;
  }
};


const rootReducer = combineReducers({
  cover: coverReducer,
  cardData: cardDataReducer,
  OutfitType: OutfitTypeReducer,
  SubOutfitType: SubOutfitTypeReducer,
  colorType: colorReducer,
  subIndex: subIndexReducer,
  loading: loadingReducer,
});

const store = configureStore({
  reducer: rootReducer
});

export default store;
